// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-blog-single-js": () => import("./../../../src/templates/Blog/single.js" /* webpackChunkName: "component---src-templates-blog-single-js" */),
  "component---src-templates-projects-single-js": () => import("./../../../src/templates/Projects/single.js" /* webpackChunkName: "component---src-templates-projects-single-js" */)
}

